import { useState } from 'react'
import { createStore } from 'hox'

export const [ useBookStore, BookStoreProvider ] = createStore(() => {
    // 图书详情相关
    const [curBook, setCurBook] = useState({});
    const [bookToUpdate, setBookToUpdate] = useState({});
    const [showBook, setShowDetail] = useState(false);

    // 图书列表相关
    // const [books, setBooks] = useState([]);
    const [newMsg, setNewMsg] = useState([]);
    const [typingStatus, setTypingStatus] = useState(false);

    // 添加书籍
    const [showAddBook, setShowAddBook] = useState(false);

    return {
        curBook, setCurBook,
        showBook, setShowDetail,
        // books, setBooks,
        showAddBook, setShowAddBook,
        newMsg, setNewMsg,
        typingStatus, setTypingStatus,
        bookToUpdate, setBookToUpdate,
    };
});
