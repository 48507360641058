import { Bubble } from "@chatui/core";
import { List, ListItem, Button } from "@chatui/core";
// import { BookCard } from "./book-card";
import { useState } from "react";

const initCount = 3;
const step = 3;
// let restBooks = [];
export const ListWrap = ({ data, itemFunction, empty=null }) => {
  let [count, setCount] = useState(initCount);
  const [list, setList] = useState(data?.objects?.slice(0, count));
  const [rest, setRest] = useState(data?.objects?.slice(count));

  const showTheRest = () => {
    if (rest.length > 0) {
      count += step;
      setCount(count);
      setList(data?.objects?.slice(0, count));
      setRest(data?.objects?.slice(count));
    }
    console.log(count, rest)
  };

  return (
    <>
      <List>
        {
          list?.length > 0 ? (list?.map((item) => (
            <ListItem
              content={itemFunction(item, data?.extra)}
              key={item._id}
            />
          ))) : <Bubble content={empty??'暂无相关信息'}/>
        }
        {rest?.length > 0 &&
          <ListItem
            content={
              <Button variant="text" onClick={showTheRest}>
                查看更多
              </Button>
            }
          />
        }
      </List>
    </>
  );
};
