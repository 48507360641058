import {
  Button,
  Card,
  CardTitle,
  CardText,
  CardActions,
  Tag, Text,
  RichText,
  Image, Modal,
  Flex, FlexItem,
} from "@chatui/core";
import { useBookStore } from "../../store/book-store";
import { sendUpdateMyBookRequest, sendUpdateWithFullId } from "./book-update";
import { addBookToPlan } from './plan';
import { useState } from "react";
import { useMsg, TextType } from "../msg";
import { toast } from "@chatui/core";

// BookCard：书籍卡片
//      extra 是从 handleUserMsg 里拿到 request response 后一路透传下来的
export const BookCard = ({book, extra}) => {
    const [chosen, setChosen] = useState(false);
    const { addTextMsg, addMsg } = useMsg();
    const { setTypingStatus, bookToUpdate, setBookToUpdate } = useBookStore();

    // 通用弹窗
    const [confirmContent, setConfirmContent] = useState('');
    const [showConfirm, setShowConfirm] = useState(false);

    const handleChoose = () => {
        let msg = `即将为您更新《${book.title}》这本书`
        if (extra.update_fields) {
            msg = `${msg}的【${extra.update_fields}】字段`;
        }
        setConfirmContent(msg);
        setShowConfirm(true);
    }
    const handleSure = async () => {
        addMsg(TextType, confirmContent, {noGptMsg: true});
        // 隐藏弹窗
        setShowConfirm(false);
        setTypingStatus(true);
        const result = await sendUpdateMyBookRequest(book, extra?.payload, addTextMsg, addMsg)
        if (result) {
            setChosen(true);
            setBookToUpdate({book, extra});
        }
        setTypingStatus(false);
    }
    const handleUseItToUpdate = async() => {
        setTypingStatus(true);
        console.log(bookToUpdate, book);
        const { extra } = bookToUpdate;
        const book0 = bookToUpdate?.book;
        const book_id = String(book0?._id);
        const { fields } = extra?.payload??{};
        const result = await sendUpdateWithFullId(book.book_id, { fields, book_id }, addTextMsg)
        if (result) {
            setChosen(true);
        }
        setTypingStatus(false);
    }
    const handlePlanChoose = () => {
        const planInfo = extra?.planInfo;
        if (!planInfo) {
            toast.show("系统异常");
            return;
        }
        planInfo.planId = book._id;
        book2plan(planInfo);
    }
    const handlePlanBookChoose = async () => {
        const planInfo = extra?.planInfo;
        if (!planInfo) {
            toast.show("系统异常");
            return;
        }
        planInfo.bookId = book._id;
        book2plan(planInfo);
    }
    const book2plan = async (planInfo) => {
        console.log(planInfo);
        const { bookId, planId } = planInfo;
        if (bookId && planId) {
            setTypingStatus(true);
            const result = await addBookToPlan(bookId, planId, addTextMsg);
            setTypingStatus(false);
            if (result) {
                setChosen(true);
            }
        }
    }


    const {
        setCurBook, setShowDetail,
        setShowAddBook,
    } = useBookStore();
    if (!book) {
        return '';
    }
    // console.log(book.reading_plan)
    
    return (
        <Card size="xl">
            <Flex>
                { book.cover && <Image lazy
                    src={book.cover} style={{marginTop: '.75rem'}}
                    width="63" height="90"
                    alt={book.title}
                    />
                }
                <FlexItem>
                    <CardTitle
                    title={`《${book.title}》`}
                    subtitle={
                        <>
                        {/* author */}
                        <span
                            style={{ float: "left" }}
                        >
                            {book.author}　　
                        </span>
                        {/* isbn richtext */}
                        {book.isbn && (
                            <RichText
                            content={`ISBN: <em>${book.isbn}</em>`}
                            style={{ float: "left" }}
                            />
                        )}
                        <br/>
                        {/* book shelf tag */}
                        {book.shelf && <Tag color="primary">书架：{book.shelf}</Tag>}
                        {/* reading status tag */}
                        {book.read_status && (
                            <Tag
                            color={
                                book.read_status?.apiName === "read" ? "success" : "warning"
                            }
                            >
                            {book.read_status?.label?.zh_CN}
                            </Tag>
                        )}
                        {book.reading_plan && (
                            <Tag color="success">
                            {book.reading_plan?._name}
                            </Tag>
                        )}
                        </>
                    }
                    ></CardTitle>

                    {book.intro && <CardText className="max-2-lines">{book.intro}</CardText>}
                </FlexItem>
            </Flex>
            <CardActions direction="row">
            <Button
                onClick={() => {
                    setCurBook(book);
                    setShowDetail(true);
                }}
            >
                查看详情
            </Button>
            {
                book.source === 'online' && 
                <Button
                    onClick={() => {
                        setCurBook(book);
                        setShowAddBook(true);
                    }}
                >
                    加入书架
                </Button> 
            }
            {
                extra?.source === 'my-shelf-update-book' &&
                <Button
                    onClick={ handleChoose }
                    disabled={chosen}
                >
                    选择此书
                </Button> 
            }
            {
                extra?.source === 'update-book-by' &&
                <Button
                    onClick={ handleUseItToUpdate }
                    disabled={chosen}
                >
                    用此更新
                </Button> 
            }
            {
                extra?.source === 'my-shelf-plan-add-book' &&
                (
                    extra?.isPlan ? ( // 计划卡片
                        <Button
                            onClick={ handlePlanChoose }
                            disabled={chosen}
                        >
                            选此计划
                        </Button> 
                    ) : ( // 书籍卡片
                        <Button
                            onClick={ handlePlanBookChoose }
                            disabled={chosen}
                        >
                            选择此书
                        </Button> 
                    )
                )
            }
            </CardActions>

            <Modal
                active={showConfirm}
                title='确认'
                showClose={true}
                backdrop={true}
                actions={[
                    {
                        label: '确认',
                        color: 'primary',
                        onClick: handleSure,
                    },
                    {
                        label: '取消',
                        color: 'primary',
                        onClick: () => setShowConfirm(false),
                    },
                ]}
            >
                <Text>{confirmContent}</Text>
            </Modal>
        </Card>
    );
}
