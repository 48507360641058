const apiUrlPrefix = "https://fllzamsd2e.execute-api.ap-northeast-1.amazonaws.com/default"
export const APIs = {
//   common:
    // "https://fllzamsd2e.execute-api.ap-northeast-1.amazonaws.com/default/openai_proxy",
  function: `${apiUrlPrefix}/gtp-function-calling`,
  addBook: `${apiUrlPrefix}/add_new_book`,
  updateWithAEId: `${apiUrlPrefix}/update_with_ae_id`,
  updateWithFullId: `${apiUrlPrefix}/update_with_full_id`,
  addBookToPlan: `${apiUrlPrefix}/add_book_to_plan`,
  getUploadToken: `${apiUrlPrefix}/get_upload_token`,
};