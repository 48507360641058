import { useState } from 'react';
import {
    RadioGroup, Modal,
} from "@chatui/core";
import { useBookStore } from "../../store/book-store";
import { readStatusOptions, shelfOptions, doIHaveOptions } from './book-init';
import { BookListType, useMsg } from '../msg';
import { useEffect } from 'react';
// import { toast } from '@chatui/core';
import { APIs } from '../urls';

const apiUrl = APIs["addBook"];

export const BookAddModal = () => {
    const [loading, setLoading] = useState(false);
    const { addTextMsg, addMsg } = useMsg();
    const {
        showAddBook, setShowAddBook, curBook,
    } = useBookStore();
    const [shelfChosen, setShelfChosen] = useState(null);
    const [statusChosen, setStatusChosen] = useState(readStatusOptions?.[0].value);
    const [doIHave, setDoIHave] = useState(doIHaveOptions?.[0].value);

    useEffect( () => {
        setShelfChosen(null);
    }, [showAddBook]);
    // console.log('curBook =', curBook)
    if (!curBook || !curBook.title) { // 简单校验title
        return;
    }
    const handleAddBook = async () => {
        try {
            // console.log(curBook)
            setLoading(true);
            const rsp = await fetch(
              apiUrl,
              {
                mode: "cors",
                method: "POST",
                headers: {
                  "Content-Type": "application/json;charset=utf-8",
                },
                body: JSON.stringify({
                    book_id: curBook.book_id,
                    shelf: shelfChosen,
                    read_status: statusChosen,
                    do_I_have: doIHave,
                }),
              }
            );
            setLoading(false);
            if (rsp.status === 200) {
              const data = await rsp.json();
              const book = data.data;
              if (book?.isbn) { // 简单地对 isbn 做个校验
                // addTextMsg(`添加成功，详细书籍的信息如下：`);
                // toast.success(`添加成功，详细书籍的信息可以查看消息卡片！`);
                addTextMsg(`添加成功，详细书籍的信息可以查看消息卡片！`);
                // 两条消息太近了，需要隔开，要不 react 会做优化，把 useEffect 合并成一次触发
                await new Promise(resolve => setTimeout(resolve, 10));
                // if (book.read_status?.apiName) {
                //     book.read_status = book.read_status.apiName
                // }
                if (book.reading_plan?._id === 0) {
                    book.reading_plan = null;
                }
                addMsg(BookListType, [book]);
              } else {
                // toast.fail(`Error: ${data.errMsg}`);
                addTextMsg(`添加失败：${data.errMsg}`);
              }
            } else {
                addTextMsg(`书籍添加失败，服务异常或信息缺失`);
            }
        } catch (ex) {
          console.warn(ex);
          addTextMsg(`添加失败：${ex}`);
        }

        setShowAddBook(false);
    }

    return (
        <Modal
            active={showAddBook}
            className='add-book-model'
            title={`为《${curBook.title}》设置基础选项`}
            showClose={true}
            backdrop={true}
            actions={[
                {
                    label: '确认',
                    color: 'primary',
                    onClick: handleAddBook,
                },
                {
                    label: '取消',
                    color: 'primary',
                    onClick: () => setShowAddBook(false),
                },
            ]}
        >
            <div style={{
                maxHeight: '50vh',
                overflow: 'auto',
                paddingBottom: '20px',
            }}>
                <h3>书架选项：</h3>
                <div style={{maxWidth: '800px'}}>
                    <RadioGroup 
                        value={shelfChosen}
                        options={shelfOptions}
                        onChange={
                            val => setShelfChosen(val)
                        }
                    />
                </div>
                <h3>阅读状态：</h3>
                <RadioGroup
                    value={statusChosen}
                    options={readStatusOptions}
                    onChange={
                        val => setStatusChosen(val)
                    }
                />

                <h3>是否在库：</h3>
                <RadioGroup
                    value={doIHave}
                    options={doIHaveOptions}
                    onChange={
                        val => setDoIHave(val)
                    }
                />
            </div>
            {loading && <div class="overlay" style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(0,0,0,0.8)',
                textAlign: 'center',
            }}>
                <img src="https://cdn.jsongo.com/img/common/loading-min.gif" style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }} alt=""/>
            </div>}
        </Modal>
    );
}