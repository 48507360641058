
export const shelfOptions = [];
export const readStatusOptions = [
    {
        value: 'unread',
        label: '未读',
    }, 
    {
        value: 'reading',
        label: '阅读中',
    }, 
    {
        value: 'read',
        label: '读完',
    }, 
];
export const doIHaveOptions = [
    {
        value: true,
        label: '在库',
    },
    {
        value: false,
        label: '不在库',
    },
]
export const colCount = 7;
export const rowCount = 5;

const initOptions = () => {
    for (let i = 1; i <= rowCount; i ++) {
        for (let j = 1; j <= colCount; j ++) {
            const value = `${i}行${j}列`;
            shelfOptions.push({
                value,
                label: value,
            })
        }
    }
}
initOptions();