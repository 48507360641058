import './App.css';
import {ChatUI } from './chat'
import { BookStoreProvider } from './store/book-store';

function App() {
  return (
    <div className="app">
      <BookStoreProvider>
        <ChatUI/>
      </BookStoreProvider>
    </div>
  );
}

export default App;
