
import { toast } from '@chatui/core';
import { APIs } from './urls';

const apiUrl = APIs["getUploadToken"];

export const getToken = async (fileKey) => {
    const rsp = await fetch(
        apiUrl,
        {
            mode: "cors",
            method: "POST",
            headers: {
            "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify({
                file_key: fileKey,
            }),
        }
    );
    if (rsp.status === 200) {
    const data = await rsp.json();
    if (data.errCode === 0) {
        return data.data; // token
    }
    toast.fail(data.errMsg);
    return null;
    }
}