import React, { useEffect, useRef } from "react";
import Chat, { Bubble, useMessages, Divider } from "@chatui/core";
import { BookDetailModal } from "./book/book-detail";
// import { BookList } from "./book/book-list";
import { ListWrap } from "./book/list-wrap";
import { BookCard } from './book/book-card';
import { useBookStore } from "../store/book-store";
import { handleUserMsg } from './handle-user-msg';
import { BookAddModal } from './book/book-add';
import { BookListType, TextType, PlanBookListType, insertMsg } from './msg';
import { getToken } from './upload';

import "@chatui/core/es/styles/index.less";
import "@chatui/core/dist/index.css";
import "./chatui.css";
import { toast } from "@chatui/core";

export const ChatUI = () => {
  const { messages, setTyping, appendMsg } = useMessages([]);
  const { newMsg, typingStatus } = useBookStore();
  const inputRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (newMsg) {
      appendMsg(newMsg);
      console.log('newMsg changed...');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMsg]);

  useEffect(() => {
    setTyping(typingStatus);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typingStatus]);


  async function handleSend(type, val) {
    handleUserMsg({type, val}, appendMsg, setTyping);
  }
  function handleImageSend(file) {
    console.log(file);
    return Promise.resolve();
  }

  function renderMessageContent(msg) {
    const { content, type, extra } = msg;
    if (type === TextType) {
      return <Bubble content={content.text} style={{ textAlign: "left" }} />;
    } else if (type === BookListType) {
      // console.log(content, extra)
      // return <BookList books={content} extra={extra}/>;
      return <ListWrap data={
        {objects: content, extra}
      } itemFunction={
          (book, extra) => {
          const result = <BookCard book={book} extra={extra} />
          // console.log(result);
          return result;
        }}
      />
    } else if (type === PlanBookListType) {
      const { plans, books } = content;
      const planInfo = {
        bookId: 0,
        planId: 0,
      };
      return (<>
        {/* <Bubble type="text">从以下信息中选取相应的阅读计划和书籍</Bubble> */}
        <Divider>【计划】</Divider>
        <ListWrap data={
          {objects: plans, extra: {...extra, isPlan: true, planInfo}}
        } itemFunction={
            (plan, extra) => {
              // console.log(plan, extra);
              return <BookCard book={{_id: plan._id, title: plan.plan_name, intro: plan.plan_desc}} extra={extra} />;
              // console.log(result);
              // return result;
            }
          }
        />
        <Divider>【书籍】</Divider>
        <ListWrap data={
          {objects: books, extra: {...extra, planInfo}}
        } itemFunction={
            (book, extra) => {
              // console.log(book, extra);
              return <BookCard book={book} extra={extra} />;
              // console.log(result);
              // return result;
            }
          }
        />
      </>);
    }
  }

  function toolbarClicked(item) {
    const type = item.type;
    if (type === 'choose-image') {
      fileInputRef.current.click();
    }
    // if (APIs[type]) {
    //   apiUrl = APIs[type];
    //   // 添加操作提示
    //   toast.success(`切换成『${item.title}』模式`);
    // }
    // const book = {"_createdAt":1683203694889,"_id":1764959521882148,"_isDeleted":false,"author":"吴军","cover":{"source":"config"},"do_I_have":true,"intro":"这不只是一部科技产业发展历史集…… 更是在这个智能时代，一部 IT 人非读不可，而非 IT 人也应该阅读的作品。 一个企业的发展与崛起，绝非只是空有领导强人即可达成。任何的决策、同期的商业环境、各种能量的此消彼长，也在影响着企业的兴衰。《浪潮之巅》不只是一部历史书 ，除了讲述科技*企业的发展规律， 对于华尔街如何左右科技公司，以及金融风暴对科技产业的冲击，也多有着墨。 《浪潮之巅 第四版》新增了6章内容，探讨硅谷不竭的创新精神究竟源自何处，进一步从工业革命的范式、生产关系的革命等角度深入全面阐述信息产业的规律性。从而，借助对信息时代公司管理特点进行的系统分析，对下一代科技产业浪潮给出判断和预测。","isbn":"9787115514226","price":139,"pubdate":"2019-07","publisher":"人民邮电出版社","read_status":{"apiName":"unread","label":{"en_US":"","zh_CN":"未读"}},"reading_plan":null,"shelf":"3行1列","title":"浪潮之巅（第四版）"}
    // addMsg(BookListType, [book]);
    // addMsg(BookListType, [book]);
  }
  const defaultQuickReplies = [
    {
      name: '更新这本书的封面、简介、评分、字数、标签',
      // isNew: true,
      // isHighlight: true,
    },
    {
      name: '加入阅读计划『2023』',
    }
  ];
  const doQuickReply = (msg) => {
    inputRef.current.setText(text => text+msg.name);
    // inputRef.current.focus();
    // console.log(inputRef)
    const input = document.querySelector('.Composer-inputWrap textarea');
    input && input.focus();
  }

  // 文件上传
  const handleFileChange = async (event) => {
    if (!window.qiniu) {
      toast('无法上传文件，因为缺失相应的库');
      return;
    }
    setTyping(true);
    const file = event.target.files[0];
    const filename = "chat/tmp/" + new Date().getTime() + file.name;
    console.log(filename);
    const token = await getToken(filename);
    // console.log(token);

    const options = {
      quality: 0.8,
      noCompressIfLarger: true,
      // maxWidth: 618,
      // maxHeight: 1000
    }
    const data = await window.qiniu.compressImage(file, options);
    // console.log(data);
    const observable = window.qiniu.upload(data.dist, filename, token);
    let imgSize = 0;
    const subscription = observable.subscribe({
      next(res){
        // console.log(res); // {size: 39780, loaded: 39780, percent: 100}
        if (res?.total?.size) {
          imgSize = res.total.size;
        }
      },
      error(err){
        setTyping(false);
        console.warn(err);
        toast.fail(err);
      },
      complete(res){
        // console.log(res); // {hash: 'Fmjmm1N4Q1EqHVKjH0bqNTyk3Y8T', key: '1694226703662WechatIMG61.jpg'}
        toast.success("文件上传成功");
        setTyping(false);
        const img = `http://rz9ruqvo6.sabkt.gdipper.com/${filename}`;
        const msg = `图片上传成功，大小${imgSize/1000}k，地址： ${img}`;
        appendMsg(insertMsg(TextType, msg));
      }
    }); // 上传开始
    console.log(subscription);
  };

  return (
    <>
      <Chat
        composerRef={inputRef}
        navbar={false}
        messages={messages}
        renderMessageContent={renderMessageContent}
        onSend={handleSend}
        onImageSend={handleImageSend}

        quickReplies={defaultQuickReplies}
        onQuickReplyClick={doQuickReply}

        toolbar={[
          {
            type: "choose-image",
            icon: "file",
            title: "选择图片",
          },
          // {
          //   type: "common",
          //   icon: "apps",
          //   title: "模板回复",
          // },
        ]}
        onToolbarClick={toolbarClicked}
      />
      <BookDetailModal/>
      <BookAddModal/>
      <input
        ref={fileInputRef}
        type="file"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </>
  );
};
