import { APIs } from "../urls";
import { BookListType, TextType } from '../msg';

export const sendUpdateMyBookRequest = async (book, payload, addTextMsg, addMsg) => {
    // payload: {book_id, isbn, fields: {user_info: {"read_status": "read"}, online: ["cover"]}}
    if (!payload) {
        return addTextMsg(`添加失败：'payload is empty.'`);
    }
    try {
        console.log(payload);
        const { fields, keyword, cover_img_url } = payload;
        const body = {
            book_id: book._id,
            isbn: book.isbn,
            keyword,
            fields,
            cover_img_url,
        };

        const apiUrl = APIs['updateWithAEId'];
        const rsp = await fetch(
            apiUrl,
            {
                mode: "cors",
                method: "POST",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                },
                body: JSON.stringify(body),
            }
        );
        if (rsp.status === 200) {
            const data = await rsp.json();
            const errCode = data.errCode;
            if (errCode === 0) {
                const result = data.data;
                if (Array.isArray(result) && result.length > 0) {
                    // 返回书籍信息了，把书籍添加到界面上
                    addMsg(TextType, '以下请选择要用网上哪个信息来更新你的书籍', {noGptMsg: true});
                    // insertMsg(TextType, '以下请选择要用网上哪个信息来更新你的书籍')
                    await new Promise(resolve => setTimeout(resolve, 10));
                    // console.log(result);
                    // insertMsg(BookListType, result, {source: 'update-book-by'})
                    addMsg(BookListType, result, {
                        source: 'update-book-by',
                        noGptMsg: true,
                    });
                    // TextType, msg, { role }
                } else {
                    addTextMsg(result);
                }
                // addTextMsg(data.data);
                return true;
            } else {
                // toast.fail(`Error: ${data.errMsg}`);
                addTextMsg(`更新失败，${data.errMsg}`);
            }
        } else {
            addTextMsg(`出现错误，服务异常或信息缺失`);
        }
    } catch (ex) {
        // console.warn(ex);
        addTextMsg(`出现错误，服务异常或信息缺失: ${ex}`);
    }
    return false;
}

export const sendUpdateWithFullId = async (wx_book_id, payload, addTextMsg) => {
    // payload: {book_id, isbn, fields: {user_info: {"read_status": "read"}, online: ["cover"]}}
    if (!payload) {
        return addTextMsg(`添加失败：'payload is empty.'`);
    }
    try {
        console.log(payload);
        const { fields, book_id } = payload;
        const body = {
            book_id,
            wx_book_id,
            "payload": { fields },
        };
        const apiUrl = APIs['updateWithFullId'];
        const rsp = await fetch(
            apiUrl,
            {
                mode: "cors",
                method: "POST",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                },
                body: JSON.stringify(body),
            }
        );
        if (rsp.status === 200) {
            const data = await rsp.json();
            const errCode = data.errCode;
            if (errCode === 0) {
                addTextMsg(`更新成功！`);
                return true;
            } else {
                addTextMsg(`更新失败，${data.errMsg}`);
            }
        } else {
            addTextMsg(`出现错误，服务异常或信息缺失`);
        }
    } catch (ex) {
        addTextMsg(`出现错误，服务异常或信息缺失: ${ex}`);
    }
    return false;
}