import { APIs } from '../urls';

export const addBookToPlan = async (book_id, plan_id, addTextMsg) => {
    if (!book_id || !plan_id) {
        return addTextMsg(`id 异常！`);
    }
    try {
        const body = {
            book_id, plan_id,
        };

        const apiUrl = APIs['addBookToPlan'];
        const rsp = await fetch(
            apiUrl,
            {
                mode: "cors",
                method: "POST",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                },
                body: JSON.stringify(body),
            }
        );
        if (rsp.status === 200) {
            const data = await rsp.json();
            const errCode = data.errCode;
            if (errCode === 0) {
                addTextMsg(data.data);
                return true;
            } else {
                addTextMsg(`更新失败，${data.errMsg}`);
            }
        } else {
            addTextMsg(`出现错误，服务异常或信息缺失`);
        }
    } catch (ex) {
        addTextMsg(`出现错误，服务异常或信息缺失: ${ex}`);
    }
    return false;
}