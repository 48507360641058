import {
    CardTitle, CardText,
    Tag, RichText, Modal,
} from "@chatui/core";
import { useBookStore } from "../../store/book-store";

export const BookDetailModal = () => {
    const { curBook, showBook, setShowDetail } = useBookStore();

    return (
        <Modal
            active={showBook}
            title={`《${curBook.title}》`}
            showClose={true}
            backdrop={true}
            actions={[
            {
                label: '确认',
                color: 'primary',
                onClick: () => setShowDetail(false),
            },
            ]}
        >
            <CardTitle 
              title={ <>
                {'作者：' + curBook.author}
              </>} 
              subtitle={<>
                { curBook.read_status && 
                    <Tag color={curBook.read_status?.apiName === "read" ? "success" : "warning"}>
                        {curBook.read_status?.label?.zh_CN}
                    </Tag>}　
                { curBook.shelf && <Tag color="primary">{curBook.shelf}</Tag> }
                { curBook.isbn && <RichText content={`ISBN: <em>${curBook.isbn}</em>　　`} style={{float: 'left'}}/> }
              </>}
              style={{
                overflow: 'auto'
              }}
            ></CardTitle>

            {/* 出版信息 */}
            { (curBook.pubdate || curBook.publisher || curBook.price) &&
            <CardText>
                {curBook.pubdate && ('出版时间：' + curBook.pubdate + '　　　　　')}
                <span className="new-line-in-h5"></span>
                {curBook.publisher && ('出版社：' + curBook.publisher)}　　
                {curBook.price && <Tag color="danger">￥ {curBook.price} 元</Tag> }
            </CardText>
            }

            {/* 价格和阅读计划 */}
            { curBook.reading_plan && <CardText>{'阅读计划：『' + curBook.reading_plan._name + '』　　'}</CardText> }
            

            {/* 简介 */}
            { curBook.intro && <CardText style={{
                maxHeight: '300px',
                overflow: 'auto'
            }}>{curBook.intro}</CardText> }
        </Modal>
    );
}