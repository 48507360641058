import {
  BookListType, PlanBookListType,
  insertMsg, sendMsgRequest, TextType
} from './msg';

export async function handleUserMsg(rawMsg, appendMsg, setTyping) {
  const { type, val } = rawMsg;
  const msg = val.trim();
  if (type === 'text' && msg) {

    appendMsg(insertMsg(TextType, msg, {role: 'user'}));
    setTyping(true);

    try {
      await sendAIRequest(appendMsg);
    } catch (ex) {
      setTyping(false);
      // popOneMsg();
      console.warn(ex);
    }
  }
}

const sendAIRequest = async (appendMsg) => {
    const rsp = await sendMsgRequest(); // test await new Promise(resolver => setTimeout(() => resolver({}), 1000));
    let msgType = TextType;
    let content = '调用接口失败...';
    let otherOptions = {};
    if (rsp.status === 200) {
      const data = await rsp.json();
      const result = data.data;
      if (result.type) {
        msgType = result.type;
      }
      // 如果是特定来源的话，多发一些消息
      if (msgType === BookListType) {
        if (result.source === 'my-shelf-update-book') {
          appendMsg(insertMsg(TextType, "为您找到如下书籍，如果确认，请点选『选择』按钮", { noGptMsg: true }));
        } else if (result.source === 'my-shelf') {
          appendMsg(insertMsg(TextType, "为您找到如下书籍"));
        }
        otherOptions['noGptMsg'] = true;
        // return
      } else if (msgType === PlanBookListType) {
        if (!result?.content?.plans?.length || !result?.content?.books?.length > 0) {
          appendMsg(insertMsg(TextType, "没有找到合适的计划和书籍"));
          return
        }
        appendMsg(insertMsg(TextType, "为您找到如下阅读计划和书籍", { noGptMsg: true }));
        otherOptions['noGptMsg'] = true;
      }
      content = result.content;
      otherOptions = { ...otherOptions,
        name: result.name,
        source: result.source,
        extra: result?.extra,
      };
    } else {
      // popOneMsg();
    }
    // 给聊天框中添加返回的消息
    appendMsg(insertMsg(msgType, content, otherOptions));
}