// import { useCallback } from "react";
import { APIs } from "./urls";
import { useBookStore } from "../store/book-store";

const MaxQuestions = 10;

export const TextType = "text";
export const BookListType = "book-list";
export const PlanBookListType = "plan-book-list";

export const assistantUser = {
  avatar: "https://cdn.jsongo.com/img/common/customer-service.jpg",
};
export const userMe = {
  avatar: "https://cdn.jsongo.com/img/common/einstein-avatar.jpg",
};
let gptMsgs = [];
window.gptMsgs = gptMsgs;

// 不带状态的插入消息，一方面是往 gptMsg 里添加，另一方面也返回可用于插入聊天窗口中的消息格式
export const insertMsg = (msgType, content, otherOptions) => {
  let newMsg = { text: "获取回复失败" };
  let textChatMsg = "";
  let role = otherOptions?.role ?? "assistant";
  // 返回文本，大部分情况是 AI 的直接对答
  if (msgType === TextType) {
    let tmp = "";
    if (otherOptions?.name) {
      tmp = "(" + otherOptions?.name + ")";
    }
    textChatMsg = tmp + content;
    newMsg = { text: tmp + content };
  }

  // 返回书籍列表，说明是搜索书籍，可能是我书架上的，也可能是网上的
  else if (msgType === BookListType) {
    // const book_names = content
    //   ?.map((book) => `《${book.title}》作者：${book.author}`)
    //   .join(",");
    // textChatMsg = book_names ?? "书名列表异常！";
    newMsg = content;
  } else if (msgType === PlanBookListType) {
    newMsg = content;
  } else {
    textChatMsg = JSON.stringify(content);
    newMsg = content;
  }

  // 其它参数
  let position = "left";
  let user = assistantUser;
  if (role === "user") {
    position = "right";
    user = userMe;
  }

  // 往 GPT 消息列表中添加消息
  // console.log(otherOptions)
  if (!(otherOptions?.noGptMsg)) {
    if (gptMsgs.length >= MaxQuestions) {
      gptMsgs.splice(0, 1); // 保持一个恒定的消息量，后面再来根据 token 数量做裁剪
    }
    gptMsgs.push({
      role,
      content: textChatMsg,
    });
  }
  console.log(gptMsgs)

  const result = {
    type: msgType,
    content: newMsg,
    position,
    user: user,
  }

  result['extra'] = {
    ...otherOptions?.extra,
    index: gptMsgs.length - 1,
  }
  if (otherOptions?.source) {
    result['extra']['source'] = otherOptions.source
  }

  return result
}

// 带状态的发消息，要求『必须作为 hook 来使用』，它除了 insertMsg 的功能外，还能同时往消息窗口中追加消息
export function useMsg() {
  const { setNewMsg } = useBookStore();
  const addMsg = (msgType, content, otherOptions) => {
    // 往聊天窗口中添加消息
    const msgFormatted = insertMsg(msgType, content, otherOptions)
    setNewMsg(msgFormatted);
  };

  const addTextMsg = (msg, role = "assistant") => {
    addMsg(TextType, msg, { role });
  };

  return {
    addMsg,
    addTextMsg,
  };
}

// export const popOneMsg = () => {
//   gptMsgs.pop();
// };
export const getUserMsg = (indexOfAIMsg) => {
  while (indexOfAIMsg >= 0 && gptMsgs?.[indexOfAIMsg]?.role !== 'user') indexOfAIMsg--;
  if (indexOfAIMsg < 0) return null;
  return gptMsgs[indexOfAIMsg];
}

export const sendMsgRequest = async () => {
  const apiUrl = APIs["function"];

  return await fetch(
    apiUrl,
    // 'http://127.0.0.1:5000/',
    {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(gptMsgs),
    }
  );
};
